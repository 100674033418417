import { useEffect } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';

import { Home } from './pages/home';
import { Notfound } from './pages/error';


export const App = () => {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      // if there's a hash in the URL (i.e., "#my-id"), scroll to it
      let id = location.hash.replace('#', '');
      let element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth"
        });
      }
    } else {
      // otherwise, scroll to the top of the page
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='*' element={<Notfound />} />
    </Routes>
  )
}
