import { extendTheme } from '@chakra-ui/react'

import '@fontsource/libre-baskerville/700.css';
import '@fontsource/roboto';

const theme = extendTheme({
  fonts: {
    body: `'Roboto', sans-serif`,
  },
})

export default theme