import {
  Flex, Box, Button, ButtonGroup, Spacer, Container, HStack, Stack, Text,
  Center, Heading, Table, Tbody, Tr, Td, TableContainer, SimpleGrid, Image, Link, Avatar,
  VStack
} from "@chakra-ui/react";

import { Link as Scroll } from 'react-scroll';
import { ButtonMenu } from "../components/menu";

// logos
import HCJI from '../images/hcji.png'

// people
import BozhenPeng from '../images/people/bozhen.png'
import AlbertYao from '../images/people/albert.png'
import MosesStewart from '../images/people/moses.png'

// advisor
import KaiMitsuzawa from '../images/people/kai.jpg'


export const Home = () => {
  const date = new Date().getFullYear();

  const customHeadingStyles = {
    fontFamily: "Libre Baskerville, sans-serif",
  };


  return (
    <div>
      <Box h={'auto'} pb={'100px'} background={'#610C04'} alignContent={'center'}>
        <Container as={Flex} position={'relative'} top={10} right={0} left={0} height={'70px'} maxW={'6xl'} alignItems={'center'} justifyContent={'space-evenly'}
          width={'100%'} alignContent={'center'} rounded={'md'}>
          <HStack>
            <Box>
              <Button as={Scroll} to={"#"} color='white' variant='ghost' colorScheme='blackAlpha' aria-label='Home Button' fontSize={'2xl'} >
                <Image src={HCJI} height={90} width={100} alt="HCJI" />
              </Button>
            </Box>
          </HStack>
          <Spacer />
          <HStack spacing={6} display={{ base: "none", md: "flex" }}>
            <ButtonGroup spacing={4}>
              <Button as={Scroll} to={"about"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='about Button' fontSize={'2xl'} >
                The Organization
              </Button>
              <Button as={Scroll} to={"member"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='service Button' fontSize={'2xl'} >
                Members
              </Button>
              <Button as={Scroll} to={"member-event"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='event Button' fontSize={'2xl'} >
                Event
              </Button>
              <Button as={Scroll} to={"contact"} color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='contact Button' fontSize={'2xl'} >
                Contact
              </Button>
              <Button as="a" href="https://www.corporate.jelper.co/" color='white' shadow="md" variant='ghost' colorScheme='blackAlpha' aria-label='companySummit Button' fontSize={'2xl'} >
                企業様はこちら
              </Button>
            </ButtonGroup>
          </HStack>
          <HStack spacing={6} display={{ base: "flex", md: "none" }}>
            <ButtonMenu />
          </HStack>
        </Container>
        <Box mt={'20vh'} color='White' textAlign={'center'}>
          <Heading as='h1' fontSize={{ base: "4xl", md: '6xl' }} sx={customHeadingStyles}>Harvard College Japan Initiative</Heading>
        </Box>
      </Box>

      <Box background={'white'} color='black' textAlign={'center'} mb={5} >
        <Container pt={20} pb={20} id="about" right={0} left={0} maxW={'4xl'} >
          <Heading size='xl'>The Organization</Heading>
          <Text mt={10} fontSize='2xl' textAlign={'center'} >Harvard College Japan Initiative is a student-run organization dedicated to fostering greater interaction and understanding between students from the U.S. and Japan.</Text>
        </Container>
        <Container maxW={'4xl'} id="member">
          <Heading mb={5}>Members</Heading>
          <Text fontSize='2xl' textAlign={'center'}>Harvard College Japan Initiative Member</Text>
          <SimpleGrid columns={{ base: 2, md: 3 }} spacingX='40px' spacingY='20px' my={'16'}>
            <VStack>
              <Text fontSize={'3xl'}>President</Text>
              <Avatar size='2xl' name='Dan Abrahmov' src={BozhenPeng} />
              <Text fontSize={'2xl'}>Bozhen Peng</Text>
            </VStack>
            <VStack>
              <Text fontSize={'3xl'}>Vide President</Text>
              <Avatar size='2xl' name='Albert Yao' src={AlbertYao} />
              <Text fontSize={'2xl'}>Albert Yao</Text>
            </VStack>
            <VStack>
              <Text fontSize={'3xl'}>Member</Text>
              <Avatar size='2xl' name='Moses Stewart' src={MosesStewart} />
              <Text fontSize={'2xl'}>Moses Stewart</Text>
            </VStack>
            <VStack>
              <Text fontSize={'3xl'}>Member</Text>
              <Avatar size='2xl' name='William Boo' />
              <Text fontSize={'2xl'}>William Boo</Text>
            </VStack>
            <VStack>
              <Text fontSize={'3xl'}>Member</Text>
              <Avatar size='2xl' name='Tasuku Ono' />
              <Text fontSize={'2xl'}>Tasuku Ono</Text>
            </VStack>
            <VStack>
              <Text fontSize={'3xl'}>Member</Text>
              <Avatar size='2xl' name='Noah Brune' />
              <Text fontSize={'2xl'}>Noah Brune</Text>
            </VStack>
            <VStack>
              <Text fontSize={'3xl'}>Member</Text>
              <Avatar size='2xl' name='Nicholas Lyu' />
              <Text fontSize={'2xl'}>Nicholas Lyu</Text>
            </VStack>
          </SimpleGrid>
        </Container>

        <Container maxW={'4xl'} id="advisor">
          <Heading mb={5}>Adviser</Heading>
          <Text fontSize='2xl' textAlign={'center'}>Harvard College Japan Initiative Adviser</Text>
          <SimpleGrid columns={{ base: 1, md: 2 }}  my={'16'}>
            <VStack>
              <Avatar size='2xl' name='Kai Mitsuzawa' src={KaiMitsuzawa} />
              <Text fontSize={'2xl'}>Kai Mitsuzawa</Text>
            </VStack>
            <VStack>
              <Avatar size='2xl' name='Hiroyuki Katsuta' />
              <Text fontSize={'2xl'}>Hiroyuki Katsuta</Text>
            </VStack>
          </SimpleGrid>
        </Container>

        <Box textAlign={'center'} mb={10} id="member-event">
          <Heading mb={12} style={{ fontWeight: 'bold' }}>Event</Heading>
          <Flex direction={{ base: "column", md: "row" }} justify="center" align="start" style={{ marginTop: '30px' }}>
            {/* Container for Japan Day */}
            <Link href="https://info.jelper.co/japan-day-2023" isExternal variant="outline">
              <Box width={{ base: "100%", md: "400px" }} ml={{ base: "0", md: "6" }} mt={{ base: 20, md: 0 }}>
                <Flex direction="column" align="center">
                  <Flex height="140px" alignItems="center">
                    <Image mb={8} height={130} src={HCJI} alt="Japan Day" borderRadius="50%" objectFit="cover" />
                  </Flex>
                  <Heading fontSize={'2xl'} mb={3}>Japan Day</Heading>
                  <Text fontSize={'3xm'} color='gray.900' mb={4}>by Harvard Undergraduate Japan Initiative</Text>
                  <Text fontSize={'lg'} align={'left'} padding={4} mb={4}>
                    Spark interest across Harvard and beyond in all dimensions of Japan. Immerse in enlightening conversations through panel discussions on business, startups, and pop culture from November 15-16, 2023 (EST).
                  </Text>
                </Flex>
              </Box>
            </Link>
          </Flex>
        </Box>


        <Box textAlign={'center'} mb={10} id="contact">
          <Heading>Contacts</Heading>
        </Box>
        <Center>
          <TableContainer mb={20}>
            <Table variant='simple' fontSize={'xl'}>
              <Tbody>
                <Tr>
                  <Td fontWeight={'bold'}>Name</Td>
                  <Td>Harvard College Japan Initiative</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>Address</Td>
                  <Td>64 Linnaean St, Cambridge, MA 02138, United States</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>Email Address</Td>
                  <Td>huji.connect@gmail.com</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>President</Td>
                  <Td>Bozhen Peng</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>Vice President</Td>
                  <Td>Albert Yao</Td>
                </Tr>
                <Tr>
                  <Td fontWeight={'bold'}>Advisor</Td>
                  <Td>Kai Mitsuzawa and Hiroyuki Katsuta</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Center>
      </Box >
      {/* Footer */}
      <Container
        as={Stack} maxW={'6xl'} mt={"auto"}
        direction={{ base: 'column', md: 'row' }} justify={{ base: 'center', md: 'space-between' }} align={{ base: 'center', md: 'center' }}
        mb={4} spacing={4}
      >
        <Text>© {date} Harvard College Japan Initiative. All rights reserved</Text>
      </Container>
    </div >
  );
};