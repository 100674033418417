import {
    Button, IconButton, Menu, MenuButton, MenuList, MenuItem, MenuItemOption, MenuGroup, MenuOptionGroup, MenuDivider, VStack, Box,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link as RouterLink } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';



export const ButtonMenu = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuList = "menuList";
    useEffect(() => {
        const menuL = document.getElementById(menuList);
        if (isMenuOpen) {
            if (menuL) {
                menuL.style.overflow = 'hidden';
            }
        } else {


            // 當選單關閉時,允許捲動
            if (menuL) {
                menuL.style.overflow = 'unset';
            }
        }
    }, [isMenuOpen]);

    return (
        <Menu >
            <MenuButton as={IconButton} onClick={() => setIsMenuOpen(!isMenuOpen)} size='md' variant='outline' _active={{
                backgroundColor: 'transparent', borderWidth: "0px"
            }} icon={isMenuOpen ? <AiOutlineClose /> : <GiHamburgerMenu />}></MenuButton>
            <MenuList id={menuList} background={'white'} alignItems={'center'} boxShadow="3px 3px 5px rgba(0, 0, 0, 0.5)">
                <VStack justifyContent="center">
                    <MenuItem as={RouterLink} to={"/"} mb={2} mt={2} onClick={() => setIsMenuOpen(false)} color="black" borderWidth="0px" background="white" aria-label='about Button' fontSize={'l'} fontWeight="bold"
                    >
                        <Box width="100%" textAlign="center">
                            The Club
                        </Box>
                    </MenuItem>
                    <MenuItem as={RouterLink} to={"/#service"} mb={2} onClick={() => setIsMenuOpen(false)} borderWidth="0px" background="white" color="black" aria-label='service Button' fontSize={'l'} fontWeight="bold">
                        <Box width="100%" textAlign="center">Service</Box>
                    </MenuItem>
                    <MenuItem as={RouterLink} to={"/#member-event"} mb={2} onClick={() => setIsMenuOpen(false)} borderWidth="0px" background="white" color="black" aria-label='event Button' fontSize={'l'} fontWeight="bold">
                        <Box width="100%" textAlign="center"> Event</Box>
                    </MenuItem>
                    <MenuItem as={RouterLink} to={"/#company"} mb={2} onClick={() => setIsMenuOpen(false)} borderWidth="0px" background="white" color="black" aria-label='company Button' fontSize={'l'} fontWeight="bold">
                        <Box width="100%" textAlign="center">  Company</Box>
                    </MenuItem>
                    <MenuItem as={RouterLink} to={"/#contact"} mb={2} onClick={() => setIsMenuOpen(false)} borderWidth="0px" background="white" color="black" aria-label='contact Button' fontSize={'l'} fontWeight="bold">
                        <Box width="100%" textAlign="center">  Contact</Box>
                    </MenuItem>
                    <MenuItem as="a" href="https://www.corporate.jelper.co/" mb={2} onClick={() => setIsMenuOpen(false)} borderWidth="0px" background="white" color="black" aria-label='company2 Button' fontSize={'l'} fontWeight="bold">
                        <Box width="100%" textAlign="center"> 企業様はこちら</Box>
                    </MenuItem>

                </VStack>
            </MenuList>
        </Menu >
    );
};